<template>
  <button class="button is-primary" @click="toggleProjectHandle">
    <slot></slot>
  </button>
</template>

<script>
import useGeneral from '@/use/useGeneral'

export default {
  setup () {
    const { toggleProjectModal } = useGeneral();

    function toggleProjectHandle () {
      toggleProjectModal()
    }

    return {
      toggleProjectHandle
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
