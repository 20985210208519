import { reactive, toRefs  } from "vue";
// import api from '@/helpers/api'
// import { env } from '@/configs'

const state = reactive({
  isProjectModalActive: false,
  isSidebarOpen: false
});

export default function useGeneral() {
  const toggleProjectModal = () => {
    state.isProjectModalActive = !state.isProjectModalActive
  }

  const toggleSidebar = () => {
    state.isSidebarOpen = !state.isSidebarOpen
  }

  const setSidebarHandle = (val) => {
    state.isSidebarOpen = val
  }

  return {
    ...toRefs(state), // convert to refs when returning
    toggleProjectModal,
    toggleSidebar,
    setSidebarHandle
  }
}

